import { useState } from 'react';
import { Link } from './ui/typography';

export default function Header( {clickfnc, anchors} ) {

    const [menuOpened, setMenuOpened] = useState(false)

    const openCloseMobileMenu = () => {
        setMenuOpened(!menuOpened);
    }

    const refClicked = (ref) => {
        if(menuOpened) {
            setMenuOpened(false);
        }

        clickfnc(ref)
    }
    return (
        <div className="container w-full">
            <div className="flex sm:flex hidden pl-10 pr-10">
                <div className="flex items-center">
                    <img
                        src="./assets/logo_full.svg"
                    />
                </div>
                
                <div className="flex ml-auto items-center">
                    {anchors.map((anchor, idx) => <div key={`menu_item_${idx}`} className="ml-8 text-center"><Link className="whitespace-nowrap cursor-pointer" onClick={() => {refClicked(anchor.ref)}} >{anchor.title}</Link></div>)}
                </div>

            </div>
            <div className="flex sm:hidden flex">
                <div className="flex items-center" >
                    <img
                        src="./assets/logo_mobile.svg"
                        className="w-8 h-8"
                    />
                </div>
                <div className="flex items-center ml-auto">
                    
                    <img
                        src="./assets/logo_burger.svg"
                        className="w-8 h-8"
                        onClick={() => openCloseMobileMenu()}
                    />
                </div>
            </div>
            <div className='dropdown dropdown_menu none'>
                <ul className='text-right'>
                    {anchors.map((anchor, idx) => <li key={`menu_item_mobile_${idx}`} className={`${menuOpened ? 'pt-1 dropdown_item_active dropdown_item-' + (idx + 1) : 'dropdown_item'}`}><Link className="whitespace-nowrap cursor-pointer" onClick={() => {refClicked(anchor.ref)}} >{anchor.title}</Link></li>)}
                </ul>
            </div>
        </div>
    )
}