import { useRef } from 'react';
import Header from './Header';
import './App.css';

import { H1, H2, H3, Text } from './ui/typography';

function App() {

  const aboutRef      = useRef(null)
  const typeRef       = useRef(null)
  const psychoRef     = useRef(null)
  const pricelistRef  = useRef(null)
  const contactRef    = useRef(null)
  
  const headerAnchors = [
    {title: 'O meni',                   ref: aboutRef},
    {title: 'Način dela',               ref: typeRef},
    {title: 'Sistemska psihoterapija',  ref: psychoRef},
    {title: 'Cenik',                    ref: pricelistRef},
    {title: 'Kontakt',                  ref: contactRef}
  ]

  const scrollIntoView = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const pricelist = [
    {title: "Uvodno srečanje", price: "60", time: "50"},
    {title: "Individualna psihoterapija", price: "60", time: "50"},
    {title: "Partnerska psihoterapija", price: "80", time: "60"},
    {title: "Družinska psihoterapija", price: "90", time: "70"}
  ]

  return (
    <div className="bg-primaryBg pt-6">

      <Header anchors={headerAnchors} clickfnc={scrollIntoView} />
      <div className='absolute z-10 hidden lg:block' style={ {width: "100%", height: "100%", top: "20%", background: "radial-gradient(50% 50% at 80% 50%, rgba(221, 222, 255, 0.8) 0%, rgba(220, 221, 255, 0) 100%)"}}></div>
      <div className='absolute z-10 hidden lg:block' style={ {width: "100%", height: "100%", top: "120%", background: "radial-gradient(50% 50% at 50% 50%, rgba(221, 222, 255, 0.5) 0%, rgba(220, 221, 255, 0) 100%)"}}></div>
      <div className="container pb-24 z-20 relative">

        <div className="lg:w-1/2 m-auto flex flex-col items-center align-middle pt-24">
          <H1>Psihoterapija in psihološko svetovanje Špela Verbnik</H1>
          <Text className="text-lg pt-12 text-center">Moje ime je Špela Verbnik. Sem magistrica psihologije in specializantka sistemske psihoterapije pod supervizijo.
            Nudim psihološko svetovanje in psihoterapijo za odrasle, otroke in mladostnike ter partnersko in družinsko psihoterapijo.</Text>
        </div>

        <div className="lg:w-1/2 m-auto flex flex-col items-center align-middle pt-24">
          <div className='w-full relative'>
            <img
                  src="./assets/scaled_img_psihoterapija_verbnik.png"
              />
          </div>
        </div>

        <div className="lg:w-1/2 m-auto flex flex-col items-center align-middle pt-48" ref={aboutRef}>
          <H2>O meni</H2>
          <Text className="text-base pt-12">Zanima in navdihuje me vse, kar spominja na pestrost in globino čutenja.
            Zame so to drobni trenutki ranljivosti in sočutja, ki se porajajo v edinstvenosti človeškega srečanja.
            Ob tem mi je posebej dragocena vloga mame dvema deklicama, ki mi osvetljujeta, kako biti kar najbolj uglašena in rahločutna, pa tudi jasna in odzivna sogovornica.
          </Text>

          <Text className="text-base pt-6">O teh in drugih subtilnih procesih sem se učila iz različnih virov in na različne načine. 
            Najprej sem končala študij psihologije, ki predstavlja temelj mojega znanja. Želja po globljem razumevanju, kako spomini, misli in prepričanja (so)bivajo v podzavesti in predvsem, kakšen je njihov vpliv in potencial za spremembo, me je vodila do Društva za medicinsko hipnozo Slovenije.
            Leta 2017 sem pridobila naziv terapevtka medicinske hipnoze. 
            Nato sem se vpisala na 4-letni specialistični študij Sistemske psihoterapije na Univerzi Sigmunda Freuda v Ljubljani.
            Moji trenutni koraki so usmerjeni v pridobivanje novih (spo)znanj na doktorskem študiju na Filozofski fakulteti v Ljubljani, na področju Klinične psihologije in psihoterapije.
          </Text>

          <Text className="text-base pt-6">Vse, kar sem se učila in raziskovala se steka v moje psihoterapevtsko delo.
            Tako lahko kot zanesljiva sopotnica podprem sočloveka, ki želi raziskovati in spreminjati sebe, odnose ali težave, s katerimi se srečuje.
          </Text>

        </div>

        <div className="lg:w-1/2 m-auto flex flex-col items-center align-middle pt-48" ref={typeRef}>
          <H2>Način dela</H2>
          <Text className="text-base pt-12">Moj namen je s polno prisotnostjo nuditi varen psihoterapevtski prostor.
            Pri tem posebno pozornost namenjam soustvarjanju pristnega in toplega terapevtskega odnosa.
            Ta odnos predstavlja priložnost, da klient z ubeseditvijo vsebin notranjega sveta razvija pristen stik s seboj, bližnjimi in širšim okoljem in te odnose razvija tako, da postajajo vse bolj izpolnjujoči.
            Verjamem, da je pomemben del tega procesa krepitev (samo)zavedanja in virov moči, kot so notranja modrost, povezanost, radovednost in sočutje.
            Ti tlakujejo pot, da je tudi težje občutke v sebi lažje čutiti, sprejeti in integrirati. 
          </Text>
          <Text className="text-base pt-6">
            V spoštovanju edinstvenosti vsakega posameznika si psihoterapevtski proces prizadevam oblikovati vsakokrat na novo, in sicer tako, da stremim k prepletanju več pristopov.
            Znanja psihologije in sistemske psihoterapije dopolnjujem z narativnimi pristopi, osredotočanjem na tukaj in zdaj, prvinami k rešitvi usmerjene terapije in po želji klienta z medicinsko hipnozo.
          </Text>
          <Text className="text-base pt-6">
            Udeležila sem se tudi celostnega usposabljanja iz gestalt igralne psihoterapije, ki bogati predvsem moje psihoterapevtsko delo z otroki, mladostniki in njihovimi družinami.
          </Text>
        </div>

        <div className="lg:w-1/2 m-auto flex flex-col items-center align-middle mt-12 p-12 bg-secondaryBg/10 rounded-lg border-[1px] border-secondaryBg" ref={psychoRef}>
          <H3 className="text-lg">Sistemska psihoterapija</H3>
          <Text className="text-base pt-12">Osrednja misel sistemske psihoterapije temelji na pomembnosti odnosov.
            Posameznika razume celostno, kot del sistema, in kot takšnega močno vpletenega v medosebne odnose in vzorce znotraj izbranega (partnerskega, družinskega ali družbenega) sistema.
            Hkrati pa je pomemben tudi intrapsihični vidik, saj je vsak posameznik zase kompleksen sistem delov, ki jih nosi v sebi.
            Pri tem je opogumljajoče osrednje sistemsko načelo, ki pravi, da še tako majhna sprememba venomer vpliva na vse njegove dele in na sistem kot celoto. 
          </Text>
        </div>

        <div className="lg:w-1/2 m-auto flex flex-col items-center align-middle pt-48">
          <H2>Etična zaveza</H2>
          <Text className="text-base pt-12">Pri psihoterapevtskem delu sem zavezana k popolni zaupnosti in spoštovanju Etičnega reda Univerze Sigmunda Freuda Dunaj - 
            podružnica Ljubljana, Etičnega kodeksa Evropske krovne zveze za psihoterapijo (EAP) in Zakona o varstvu osebnih podatkov Republike Slovenije.
            Zavezana sem k neprestani osebni in strokovni rasti in izobraževanju.
            Psihoterapevtsko delo izvajam pod supervizijo psihoterapevtke Jerice Penko (mag. pst. znanosti) in psihologinje Andreje Barbare Jaš (spec. ZDT). 
          </Text>
        </div>

        

        <div className="lg:w-1/2 m-auto flex flex-col items-center align-middle pt-48" ref={pricelistRef}>
          <H2>Cenik</H2>
          <div className='pt-12 w-full'>
            {pricelist.map((value, idx) => 
              <div key={`pricelist_${idx}`} className={`flex flex-row w-full pt-4 pb-4 ${idx != pricelist.length - 1 ? "border-b-[1px]" : ""} border-secondaryBg`}>
                <div className='w-1/3'><Text className="sm:m-auto text-left text-base">{value.title}</Text></div>
                <div className='w-1/3'><Text className="sm:m-auto sm:text-center lg:text-left text-base">{value.time} minut</Text></div>
                <div className='w-1/3'><Text className="sm:m-auto sm:text-center lg:text-left text-base">{value.price} €</Text></div>
              </div>
            )}
          </div>
          <Text className="text-base pt-12">V primeru, da se želite naročiti na termin ali imate kakšno vprašanje, vabljeni, da mi pišete preko spletne pošte ali me pokličete.
            V primeru, da se ne javim, vas ob prvi priložnosti pokličem nazaj.</Text>
        </div>

        <div className="lg:w-1/2 m-auto flex flex-col text-center items-center align-middle pt-24" ref={contactRef}>
          <H2>Kontakt</H2>

          <Text className="text-base pt-6 w-auto">spela.verbnik@gmail.com</Text>
          <Text className="text-base pt-6 w-auto">041 590 888</Text>
          <Text className="text-base pt-6 w-auto">Kraj psihoterapevtskih srečanj:</Text>
          <Text className="text-base w-auto">Cankarjeva cesta 9</Text>
          <Text className="text-base w-auto">1000 Ljubljana</Text>
        </div>

      </div>
      <div className='border-t-[1px] flex border-secondaryBg w-full lg:pb-24 pb-12'>
        <Text className="text-base lg:pt-24 pt-12 w-auto m-auto">© 2024 Špela Verbnik</Text>
      </div>
    </div>
  );
}

export default App;
