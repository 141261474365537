import * as React from "react"

import { cn } from "../lib/utils";

const H1 = React.forwardRef(({ className, ...props }, ref) => (
<h1
    ref={ref}
    className={cn("lg:text-5xl text-3xl font-playfair text-center text-primary", className)}
    {...props} />
))
H1.displayName = "H1";

const H2 = React.forwardRef(({ className, ...props }, ref) => (
<h2
    ref={ref}
    className={cn("w-full font-playfair text-4xl text-primary", className)}
    {...props} />
))
H2.displayName = "H2";

const H3 = React.forwardRef(({ className, ...props }, ref) => (
<h3
    ref={ref}
    className={cn("w-full font-playfair text-primary color-primary", className)}
    {...props} />
))
H3.displayName = "H3";

const Text = React.forwardRef(({ className, ...props }, ref) => (
<p
    ref={ref}
    className={cn("w-full font-inter text-baseColor font-light font-inter text-left", className)}
    {...props} />
))
Text.displayName = "Text";

const Link = React.forwardRef(({ className, ...props }, ref) => (
<a
    ref={ref}
    className={cn("w-full font-inter text-primary", className)}
    {...props} />
))
Link.displayName = "Link";

export {
    H1, H2, H3, Text, Link
}
